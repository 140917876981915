require("sweetalert");

import "bootstrap/js/src/tab";
import "bootstrap/js/src/modal";
import "./slider";
import "./steps";
import "./number";
import "./modal";
import "./discount";

import jQuery from "jquery";

window.$ = window.jQuery = jQuery;

const tailwindBreakpoints = {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
};

// swal("Here's the title!", "...and here's the text!");

const breakpoint = window.matchMedia(
    "(max-width:" + tailwindBreakpoints.lg + "px)"
);

(function ($) {
    const mobileSectionsWrapper = $(".js-mobile-section-wrapper");

    $(".js-rating-picker").each(function () {
        var stars = $(this).find(".star");
        var attachedInput = $($(this).data("attached-input"));
        stars.on("mouseover", function () {
            $(this)
                .prevAll()
                .addBack()
                .not(".submitted")
                .addClass("text-secondary-100");
        });
        stars.on("mouseout", function () {
            $(this)
                .prevAll()
                .addBack()
                .not(".submitted")
                .removeClass("text-secondary-100");
        });
        stars.on("click", function () {
            $(this)
                .siblings(".star")
                .removeClass("text-secondary-100 submitted");
            $(this)
                .prevAll()
                .addBack()
                .addClass("text-secondary-100 submitted");
            attachedInput.val(stars.filter(".submitted").length);
        });
    });

    function inlineAllSvgImages(selector) {
        selector.find("img.svg-icon").each(function () {
            const $img = $(this);
            const imgID = $img.attr("id");
            const imgClass = $img.attr("class");
            const imgURL = $img.attr("src");
            $.get(
                imgURL,
                function (data) {
                    const $svg = $(data).find("svg");
                    if (typeof imgID !== "undefined") {
                        $svg = $svg.attr("id", imgID);
                    }
                    if (typeof imgClass !== "undefined") {
                        $svg = $svg.attr("class", imgClass + " replaced-svg");
                    }
                    $svg = $svg.removeAttr("xmlns:a");
                    if (
                        !$svg.attr("viewBox") &&
                        $svg.attr("height") &&
                        $svg.attr("width")
                    ) {
                        $svg.attr(
                            "viewBox",
                            "0 0 " +
                                $svg.attr("height") +
                                " " +
                                $svg.attr("width")
                        );
                    }
                    $img.replaceWith($svg);
                },
                "xml"
            );
        });
    }

    function wrapMenuLinksWithTogglers(mobileSectionsWrapper, openerSelector) {
        mobileSectionsWrapper
            .find(".js-append-menu-items .menu-item >" + openerSelector)
            .each(function () {
                $(this).css("display", "block");
            });
        mobileSectionsWrapper
            .find(".js-append-menu-items .menu-item >" + openerSelector)
            .parent()
            .each(function () {
                $(this).removeClass("active");
                $(this).children(openerSelector).slideUp(0);
                $(this)
                    .find("> a")
                    .before(
                        `<div class="menu-link-container">
                        <button type="button" class="sub-menu-opener js-sub-menu-opener">
                            <span class="material-icons">expand_more</span>
                        </button>
                    </div>`
                    );
                const link = $(this).find("> a");
                $(this).find("> .menu-link-container").prepend(link);
                $(this)
                    .find(".js-sub-menu-opener")
                    .off()
                    .on("click", function () {
                        $(this)
                            .parent(".menu-link-container")
                            .next(openerSelector)
                            .slideToggle(300);
                        $(this)
                            .parent(".menu-link-container")
                            .toggleClass("active");
                    });
            });
    }

    function buildMobileMenu() {
        const menuItems = $(".js-desktop-header .js-menu-items-grab > *");
        mobileSectionsWrapper.find(".js-append-menu-items").append(menuItems);
        wrapMenuLinksWithTogglers(mobileSectionsWrapper, ".sub-menu");
    }

    function buildDesktopMenu() {
        if ($("body").hasClass("menu-open")) {
            toggleMainMenuMobile();
        }
        const menuItems = mobileSectionsWrapper.find(
            ".js-append-menu-items > *"
        );

        menuItems.find(".menu-link-container").each(function () {
            $(this).find(".sub-menu-opener").off("click");
            var link = $(this).find("> a");
            $(this).after(link);
            $(this).remove();
        });

        // menuItems.find(".sub-menu").css("display", "flex");

        const half = Math.ceil(menuItems.length / 2);
        const firstHalf = menuItems.splice(0, half);
        const secondHalf = menuItems.splice(-half);
        const desktopMenus = $(".js-desktop-header .js-menu-items-grab");

        desktopMenus.first().append(firstHalf);
        desktopMenus.last().append(secondHalf);
    }

    function toggleMainMenuMobile() {
        $("body").toggleClass("menu-open");
        mobileSectionsWrapper.find(".js-mobile-menu").toggleClass("active");
    }
    function breakpointChecker() {
        if (breakpoint.matches === true) {
            buildMobileMenu();
        } else if (breakpoint.matches === false) {
            buildDesktopMenu();
        }
    }

    const showBackground = () => {
        $(".showBackground")
            .mouseover(function () {
                $(".background-gray").removeClass("hidden");
            })
            .mouseleave(function () {
                $(".background-gray").addClass("hidden");
            });
    };

    $('input[name="payment"]').on("change", function () {
        if ($("#online").is(":checked")) {
            $(".select-bank").removeClass("hidden");
            console.log("hi");
        } else {
            $(".select-bank").addClass("hidden");
        }
    });

    $(".js-go-top").on("click", function () {
        window.scrollTo({ top: 0, behavior: "smooth" });
    });

    $(".js-mobile-menu-toggler").on("click", function () {
        toggleMainMenuMobile();
        showBackground();
    });

    if ($(document).attr("dir") === "rtl") {
        $(".js-separate-number").each(function () {
            const number = parseInt($(this).text());
            $(this).text(number.toLocaleString());
        });
    }

    $(".btn-share-product").on("click", () => {
        var inputc = document.body.appendChild(document.createElement("input"));
        inputc.value = window.location.href;
        inputc.focus();
        inputc.select();
        document.execCommand("copy");
        inputc.parentNode.removeChild(inputc);
        alert("لینک اشتراک گذاری کپی شد");
    });

    breakpoint.addEventListener("change", breakpointChecker);
    breakpointChecker();
    inlineAllSvgImages($(document));
    // show background gray when open mega menu
    showBackground();

    $(".scroll-top").click(function () {
        $("html, body").animate(
            {
                scrollTop: 0,
            },
            "slow"
        );
        return false;
    });

    $(".js-local-payment input").on("change", function () {
        $(".select-bank").slideUp(300);
        $(".select-bank input").prop("checked", false);
    });
    $(".js-online-payment input").on("change", function () {
        $(".select-bank").slideDown(300);
        $(".select-bank input").first().prop("checked", true);
    });
})(jQuery);
