import jQuery from "jquery";

const nextStep = (node, stepNumber) => {
    let step = parseInt(stepNumber) + 1;
    $(`.step-${stepNumber}`).addClass("hidden");
    $(`.step-${step}`).removeClass("hidden");
    $(`.icon-step-${step}`).addClass("text-white bg-primary-400");
    $(".prev-step").attr("disabled", false);
    node.closest(".parent-step").attr("data-step", step);
    if (step === 2) {
        $(".selected-address").prop('checked', false);
        if ($(".selected-address").prop("checked") === false) {
            node.addClass("disabled");
        }
    }

    if (step === 3) {
        $(".select-post-type").prop('checked', false);
        $(".next-step").addClass("hidden");
        $(".submit-form-cart").removeClass("hidden");
        $(".payment-type-and-bank").removeClass("hidden");
        if ($(".select-post-type").prop("checked") === false) {
            $(".submit-form-cart").addClass("disabled");
        }
    }
};

const prevStep = (node, stepNumber) => {
    let step = parseInt(stepNumber) - 1;
    $(`.step-${stepNumber}`).addClass("hidden");
    $(`.step-${step}`).removeClass("hidden");
    $(`.icon-step-${stepNumber}`).removeClass("text-white bg-primary-400");
    node.closest(".parent-step").attr("data-step", step);
    $(".next-step").removeClass("hidden");
    $(".submit-form-cart").addClass("hidden");
    $(".payment-type-and-bank").addClass("hidden");
    if (step === 1) {
        $(".prev-step").attr("disabled", true);
        $(".next-step").removeClass("disabled");
    }
};

const setAddress = (data) => {
    $(".cart-address-name").text(data.one);
    $(".cart-address-number").text(data.two);
    $(".cart-address-state").text(data.three);
    $(".cart-address-city").text(data.four);
    $(".cart-address-post").text(data.five);
    $(".cart-address-address").text(data.six);
    $(".address-id").val(data.seven);
};

const setPriceShipping = (data) => {
    const total = $(".factor-total").data("price");
    const discount = $(".factor-discount").attr("data-discount");
    const final = (parseInt(total) + parseInt(data.one)) - parseInt(discount);
    $(".factor-shipping").text(data.one);
    $(".factor-total").text(final);
    $(".factor-total").attr('data-price-shipping', data.one);
    $(".shipping-id").val(data.two);
};

(function ($) {
    $(".change-step").on("click", function () {
        const stepNumber = $(this).closest(".parent-step").attr("data-step");
        const status = $(this).attr("data-status");
        const node = $(this);
        if (status === "next") {
            nextStep(node, stepNumber);
        } else {
            prevStep(node, stepNumber);
        }
    });

    $(".next-step").each(function () {
        const status = $(".factor-total").text();
        if (parseInt(status) <= 0) {
            $(this).addClass("disabled");
            $(".submit-form-cart").addClass("disabled");
        }
    });

    $(".selected-address").on("change", function () {
        $(".next-step").removeClass("disabled");
        const data = $(this).data();
        setAddress(data);
    });

    $(".select-post-type").on("change", function () {
        const data = $(this).data();
        setPriceShipping(data);
        $(".submit-form-cart").removeClass("disabled");
    });
})(jQuery);
