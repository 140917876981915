import jQuery from "jquery";
import swal from "sweetalert";

(function ($) {
    $(".btn-discount-cart").on("click", () => {
        const code = $(".input-discount-cart").val();
        const token = $(".discount-input-token").val();
        disCount(code, token);
    });

    // delete discount code
    $(".btn-delete-discount-cart").on("click", () => {
        const elem = $(".factor-total");
        const price = elem.attr("data-price");
        const shipping = elem.attr("data-price-shipping");
        elem.text(parseInt(price) + parseInt(shipping));
        $(".factor-discount").text("0");
        $(".factor-discount").attr("data-discount", "0");
        $(".input-discount-cart").val("");
    });

    const disCount = (code, token) => {
        $.ajax({
            url: "checkDiscount",
            type: "POST",
            data: { _token: token, code: code },
            success: function (data) {
                const discount = data.discount;
                response(discount, code);
                swal({
                    text: data.message,
                    icon: data.status ? "success" : "error",
                });
            },
        });
    };

    const response = (discount, code) => {
        console.log(code)
        const elem = $(".factor-total");
        const price = elem.attr("data-price");
        const shipping = elem.attr("data-price-shipping");
        const finalPrice = price - discount + parseInt(shipping);
        $(".factor-discount").text(discount);
        $(".factor-discount").attr("data-discount", discount);
        $(".discount-id").val(code)
        elem.text(finalPrice < 0 ? 0 : finalPrice);
    };
})(jQuery);
