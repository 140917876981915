import jQuery from "jquery";

(function ($) {
    const numberItems = (num) => {
        const show = $("#buy-product").hasClass("show");
        let price = $(".modal-content-data").attr("data-price");
        let final = $(".modal-content-data").attr("data-final");
        let off = $(".modal-content-data").attr("data-discount");
        if (show) {
            const sumPrice = parseInt(price) * num;
            const sumFinal = parseInt(final) * num;

            $(".modal-price").text(sumPrice.toLocaleString());
            $(".modal-final").text(sumFinal.toLocaleString());

            if (parseInt(off) > 0) {
                const sumOff = parseInt(off) * num;
                $(".modal-discount").text(sumOff.toLocaleString());
            }
        }
    };

    const setUrl = (num) => {
        $(".js-input-count").val(num);
    };



    const addItems = (elem) => {
        const element = elem.closest(".number-items").hasClass("number-items");
        const id = elem.closest(".number-items").attr("data-id");

        if (element) {
            $.ajax({
                url: `/api/shoppingCart/${id}`,
                type: "GET",
                success: function (data) {
                    const price = data.total;
                    const discount = data.totalDiscount;
                    const shipping = data.shipping;
                    const finalPrice = data.finalTotal;
                    resolve(price, discount, shipping, finalPrice);
                },
            });
        }
    };

    const removeItems = (elem) => {
        const element = elem.closest(".number-items").hasClass("number-items");
        const id = elem.closest(".number-items").attr("data-id");

        if (element) {
            $.ajax({
                url: `/api/shoppingCart/${id}/1`,
                type: "GET",
                success: function (data) {
                    const price = data.total;
                    const discount = data.totalDiscount;
                    const shipping = data.shipping;
                    const finalPrice = data.finalTotal;
                    resolve(price, discount, shipping, finalPrice);
                },
            });
        }
    };

    const resolve = (price, discount, shipping, finalPrice) => {
        $(".factor-price").text(price);
        $(".factor-discount").text(discount);
        $(".factor-shipping").text(shipping);
        $(".factor-total").text(finalPrice);
        $(".factor-total").attr("data-price", finalPrice);
    };

    // up and down number products
    $(".button-up-and-down").on("click", function () {
        const data = $(this).data("name");
        const elem = $(this)
            .closest(".number-products-purchased")
            .find(".input-number-product");
        const value = elem.val();
        let sum = 1;
        if (data == "up") {
            sum = parseInt(value) + 1;
            addItems(elem);
        } else {
            if (value > 1) {
                sum = value - 1;
                removeItems(elem);
            }
        }
        elem.val(sum);
        numberItems(sum);
        setUrl(sum);
    });
})(jQuery);
