import jQuery from "jquery";

const setDataInModal = (img, title, off, price, finalPrice, id) => {
    const discount = price.replace(/,/g, "") - finalPrice.replace(/,/g, "");
    $(".modal-image").attr("src", img);
    $(".modal-title").text(title);
    $(".modal-final").text(finalPrice);
    $(".modal-content-data").attr("data-final", finalPrice.replace(/,/g, ""));
    $(".modal-content-data").attr("data-id", id);

    if (off) {
        $(".modal-discount").text(discount.toLocaleString());
        $(".modal-price").text(price);
        $(".modal-content-data").attr("data-price", price.replace(/,/g, ""));
        $(".modal-content-data").attr("data-discount", discount);
    } else {
        $(".modal-discount").text("0");
        $(".modal-price").text(finalPrice);
        $(".modal-content-data").attr(
            "data-price",
            finalPrice.replace(/,/g, "")
        );
        $(".modal-content-data").attr("data-discount", 0);
    }
};

const setUrl = (id) => {
    $(".js-input-id").val(id);
    $(".js-input-count").val(1);
};

const modalShowOrder = (lists) => {
    const element = jQuery.map(lists, function (product) {
        console.log(product);
        return `
            <div class="flex flex-row border border-gray-300 rounded-lg py-4 px-5 mt-3">
                <div class="w-24 h-24 border border-primary-400 rounded-lg mie-5 mb-4 sm:mb-0">
                    <img class="w-full h-full object-contain" src="${
                        product.image
                    }" alt="">
                </div>
                <div class="flex flex-col justify-around items-center w-5/6 sm:items-start">
                    <h5 class="text-lg">${product.productName}</h5>
                    <div class="flex flex-row flex-wrap w-full">
                        <div class="w-1/4">
                            <span>قیمت واحد: </span>
                            <span>${product.itemPrice}</span>
                        </div>
                        <div class="w-1/4">
                            <span class="pie-2">تعداد: </span>
                            <span>${product.qty}</span>
                        </div>
                        <div class="text-primary-400 w-1/4">
                            <span class="pie-2 ">تخفیف کل: </span>
                            <span>${product.itemDiscount}</span>
                        </div>
                        <div class="text-secondary-500 w-1/4">
                            <span class="pie-2 ">قیمت نهایی واحد: </span>
                            <span>${
                                product.itemPrice - product.itemDiscount
                            }</span>
                        </div>
                    </div>
                </div>
            </div>`;
    });
    $(".modal-show-orders").append(element);
};

const setAddressData = (
    id,
    name,
    phone,
    number,
    email,
    state,
    city,
    post,
    address,
    unit,
    plaque,
    _lat,
    _lng
) => {
    $(".modal-input-id").val(id);
    $(".modal-input-name").val(name);
    $(".modal-input-phone").val(phone);
    $(".modal-input-number").val(number);
    $(".modal-input-email").val(email);
    $(".modal-input-state").val(state);
    $(".modal-input-city").val(city);
    $(".modal-input-post").val(post);
    $(".modal-input-address").val(address);
    $(".modal-input-unit").val(unit);
    $(".modal-input-plaque").val(plaque);
    $(".modal-input-lat").val(_lat);
    $(".modal-input-lng").val(_lng);
};

const setPackageData = (id, image, price, name, hasDuaration, qty) => {
    $(".modal-product-id").val(id);
    $(".modal-package-image").attr("src", image);
    $(".modal-package-price").text(price);
    $(".modal-package-price").attr("data-price", price);
    $(".modal-package-name").text(name);
    $(".modal-package-qty").text(qty);
    if (hasDuaration == 0) {
        $(".duration-part").remove();
    }
};

const setItemData = (
    id,
    name,
    state,
    code,
    description,
    colors,
    categories,
    method,
    step,
    basePrice,
    hasDiscount,
    discountAmount,
    discountPercent,
    qty,
    qtyMaxOrder,
    status,
    price
) => {
    $(".modal-item-id").val(id);
    $(".modal-item-name").val(name);
    $(".modal-item-code").val(code);
    $(".modal-item-description").val(description);
    $(".modal-item-colors").val("زرد,نارنجی");
    $(".modal-item-categories").val(categories);
    $(".modal-item-step").val(step);
    $(".modal-item-base-price").val(basePrice);
    $(".modal-item-price").val(price);
    $(".modal-item-discount-amount").val(discountAmount);
    $(".modal-item-discount-percent").val(discountPercent);
    $(".modal-item-qty").val(qty);
    $(".modal-item-qty-max-order").val(qtyMaxOrder);
    $(".modal-item-change-state").change(function () {
        $(this).val(status);
    });
    $(".modal-item-method").val(method);
    $(".modal-item-has-discount").change(function () {
        $(this).val(hasDiscount);
    });
    $(".modal-item-image").removeAttr("required");
};

(function ($) {
    $(".btn-modal-buy").on("click", function () {
        const elm = $(this).closest(".product");
        const img = elm.find(".product-image").attr("src");
        const id = elm.find(".product-id").data("id");
        const title = elm.find(".product-title").text();
        const off = elm.find(".product-offPercent").text();
        const price = elm.find(".product-price").text();
        const finalPrice = elm.find(".product-finalPrice").text();
        setDataInModal(img, title, off, price, finalPrice, id);
        setUrl(id);
    });

    $(".btn-edit-address").on("click", function () {
        const elm = $(this).closest(".address-information");
        const id = elm.find(".address-id").text();
        const name = elm.find(".address-name").text();
        const phone = elm.find(".address-phone").text();
        const state = elm.find(".address-state").text();
        const city = elm.find(".address-city").text();
        const post = elm.find(".address-postalCode").text();
        const address = elm.find(".address-text").text();
        const unit = elm.find(".address-unit").text();
        const plaque = elm.find(".address-plaque").text();
        const number = elm.find(".address-number").text();
        const email = elm.find(".address-email").text();
        const _lat = elm.find(".address-lat").text();
        const _lng = elm.find(".address-lng").text();
        setAddressData(
            id,
            name,
            phone,
            number,
            email,
            state,
            city,
            post,
            address,
            unit,
            plaque,
            _lat,
            _lng
        );
    });

    $("#buy-product").on("hidden.bs.modal", () => {
        $(".modal-content-data").attr("data-price", "");
        $(".modal-content-data").attr("data-final", "");
        $(".modal-content-data").attr("data-discount", "");
        $(".modal-content-data").attr("data-id", "");
        $(".input-number-product").val(1);
    });

    // modal show orders in dashboard
    $(".btn-modal-products-details").on("click", function () {
        const elm = $(this).closest(".orders-data");
        const orderData = elm.data("order").items;
        modalShowOrder(orderData);
    });

    $("#details-orders").on("hidden.bs.modal", () => {
        $(".modal-show-orders").empty();
    });

    $(".buy-package-button").on("click", function () {
        const elm = $(this).closest(".package-boxes");
        const id = elm.find(".package-id").text();
        const image = elm.find(".package-image").attr("src");
        const price = elm.find(".package-price").text();
        const name = elm.find(".package-name").text();
        const hasDuaration = elm.find(".package-has-duration").text();
        const qty = elm.find(".package-product-count").text();
        setPackageData(id, image, price, name, hasDuaration, qty);
    });

    $(".btn-edit-item").on("click", function () {
        const elm = $(this).closest(".item-information");
        const id = elm.find(".item-id").text();
        const code = elm.find(".item-code").text();
        const description = elm.find(".item-description").text();
        const colors = elm.find(".item-colors").text();
        const categories = elm.find(".item-categories").text();
        const method = elm.find(".item-method").text();
        const step = elm.find(".item-step").text();
        const basePrice = elm.find(".item-base-price").text();
        const price = elm.find(".item-price").text();
        const hasDiscount = elm.find(".item-has-discount").text();
        const discountAmount = elm.find(".item-discount-amount").text();
        const discountPercent = elm.find(".item-discount-percent").text();
        const qty = elm.find(".item-qty").text();
        const qtyMaxOrder = elm.find(".item-qty-max-order").text();
        const status = elm.find(".item-status").text();
        const name = elm.find(".item-name").text();
        const state = elm.find(".item-state").text();
        setItemData(
            id,
            name,
            state,
            code,
            description,
            colors,
            categories,
            method,
            step,
            basePrice,
            hasDiscount,
            discountAmount,
            discountPercent,
            qty,
            qtyMaxOrder,
            status,
            price
        );
    });

    // onChange selection in modal
    $(".select-month-package").on("change", function () {
        var selected = $(this).find("option:selected");
        const price = $(".modal-package-price").attr("data-price");
        const months = selected.data("manifold");
        const discountPercent = selected.data("discount-percent");
        const discountPrice = selected.data("discount-price");
        var finalPrice =
            price * months -
            price * months * (discountPercent / 100) -
            discountPrice;
        if (finalPrice < 0) {
            finalPrice = 0;
        }
        //finalPrice = finalPrice - ((discountPercent/100) * finalPrice);
        //finalPrice = finalPrice - discountPrice;
        $(".modal-package-price").text(finalPrice);
    });
})(jQuery);
