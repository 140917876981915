import Swiper, { Autoplay, Navigation, Pagination, Thumbs } from "swiper";

import jQuery from "jquery";

Swiper.use([Autoplay, Navigation, Pagination, Thumbs]);

function initSingleItemSlider(
    node,
    prevEl,
    nextEl,
    autoPlay,
    paginationSlider
) {
    const config = {
        slidesPerView: 1,
        loop: true,
    };
    if (prevEl && nextEl) {
        config.navigation = {
            nextEl,
            prevEl,
        };
    }

    config.pagination = {
        el: `.${paginationSlider}`,
        clickable: true,
    };

    autoPlay &&
        (config.autoplay = {
            delay: autoPlay,
        });

    new Swiper(node, config);
}
function initMultiItemSlider(node, prevEl, nextEl, autoPlay) {
    const config = {
        slidesPerView: 4,
        spaceBetween: 10,
        loop: true,
        breakpoints: {
            0: {
                slidesPerView: 1,
            },
            500: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 4,
            },
        },
    };
    if (prevEl && nextEl) {
        config.navigation = {
            nextEl,
            prevEl,
        };
    }

    autoPlay &&
        (config.autoplay = {
            delay: autoPlay,
        });

    new Swiper(node, config);
}

function initThreeItemSlider(node, prevEl, nextEl, autoPlay) {
    const config = {
        slidesPerView: 3,
        spaceBetween: 10,
        loop: true,
        breakpoints: {
            0: {
                slidesPerView: 1,
            },
            500: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 3,
            },
        },
    };
    if (prevEl && nextEl) {
        config.navigation = {
            nextEl,
            prevEl,
        };
    }

    autoPlay &&
        (config.autoplay = {
            delay: autoPlay,
        });

    new Swiper(node, config);
}

function initGallerySlider(mainNode, thumbsNode, next, prev) {
    const thumbSlider = new Swiper(thumbsNode, {
        spaceBetween: 10,
        slidesPerView: "auto",
    });

    new Swiper(mainNode, {
        slidesPerView: 1,
        spaceBetween: 10,
        loop: true,
        autoplay: {
            delay: 5000,
        },
        navigation: {
            nextEl: next,
            prevEl: prev,
        },
        thumbs: {
            swiper: thumbSlider,
        },
    });
}

(function ($) {
    // Price range slider for store page
    $(".js-range-slider").ionRangeSlider({
        type: "double",
        grid: false,
        step: 1000,
        prefix: " ",
        onChange: function (data) {
            let minPrice = data.from;
            let maxPrice = data.to;
            $(".slider-from").val(minPrice);
            $(".slider-to").val(maxPrice);
            $(".min-price").text(minPrice.toLocaleString());
            $(".max-price").text(maxPrice.toLocaleString());
        },
    });

    $(".js-single-item-slider").each(function () {
        const node = $(this).find(".swiper-container").get(0);
        const next = $(this).find(".js-next-slide").get(0);
        const prev = $(this).find(".js-prev-slide").get(0);
        const autoPlay = $(this).data("auto-play");
        const pagination = $(this).data("pagination");
        initSingleItemSlider(node, prev, next, autoPlay, pagination);
    });

    $(".js-multi-item-slider").each(function () {
        const node = $(this).find(".swiper-container").get(0);
        const next = $(this).find(".js-next-slide").get(0);
        const prev = $(this).find(".js-prev-slide").get(0);
        const autoPlay = $(this).data("auto-play");
        initMultiItemSlider(node, prev, next, autoPlay);
    });

    $(".js-three-item-slider").each(function () {
        const node = $(this).find(".swiper-container").get(0);
        const next = $(this).find(".js-next-slide").get(0);
        const prev = $(this).find(".js-prev-slide").get(0);
        const autoPlay = $(this).data("auto-play");
        initThreeItemSlider(node, prev, next, autoPlay);
    });

    $(".js-gallery-slider").each(function () {
        const main = $(this).find(".js-gallery-main").get(0);
        const thumbs = $(this).find(".js-gallery-thumbs").get(0);
        const next = $(this).find(".js-next-slide").get(0);
        const prev = $(this).find(".js-prev-slide").get(0);
        initGallerySlider(main, thumbs, next, prev);
    });

    $(".js-image-gallery").on("click", function () {
        const image = $(this).attr("src");
        $(".preview-image").attr("src", image);
        $(".zoomImg").attr("src", image);
        $(".zoomImg").css("background-color", "white");
    });
})(jQuery);
